import React from 'react';
import { Typography } from '@material-ui/core';

const Viewfieldlarge = ({ label, name }) => {
  return (
    <div style={{ marginBottom: "16px" }}>
      <Typography
        variant="subtitle1"
        style={{ fontWeight: "bold", fontSize: "1.5rem" }}
      >
        {label}
      </Typography>
      <Typography
        variant="body1"
        style={{ fontSize: "1.4rem", color: "#555" }}
      >
        {name}
      </Typography>
    </div>
  );
};

export default Viewfieldlarge;
