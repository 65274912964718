import React, { useEffect, useState } from 'react';
import { Divider, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  Container,
  Grid,
} from '@material-ui/core';
import { useHistory } from "react-router-dom";
import useReactRouter from 'use-react-router';
import { Button as Buttonh } from '@material-ui/core';
import { green } from "@material-ui/core/colors";
import { TextField } from '@material-ui/core';
import Viewfield from '../Components/FormsUI/Viewfield';
import Viewfieldlarge from '../Components/FormsUI/Viewfieldlarge';
import HeaderAdmission from '../Components/Header/headeradmission';
import HeaderadmissionLottery from '../Components/Header/headeradmissionLottery';
const useStyles = makeStyles((theme) => ({
  formWrapper: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(8),
  },
  root: {
    color: green[900],
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "rgba(0, 0, 0, 0.23)" // default
      },
      "&.Mui-focused fieldset": {
        border: "1px solid #C4C4C4" // customized
      }
    }
  }
}));



const AdmissionLottery = () => {

  const { match } = useReactRouter();

  const [instInfo, setinstInfo] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      let res = await fetch(`https://api.shebashikkha.com/public/online-applicantion/institute-info/${match.params.id}`)
      let data = await res.json()
      setinstInfo(data?.item)
    }
    fetchData();
  }, []);

  const [textid, settextid] = useState();
  const [payId, setpayId] = useState();
  const history = useHistory();

  const [data, setdata] = useState(null);


  async function formSubmit(e) {
    e.preventDefault();
    try {
      const rawResponse = await fetch(`${process.env.REACT_APP_API_ROOT}/public/online/admission/single/applicant/info?instituteId=${match.params.id}&registrationId=${textid}`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        // body: JSON.stringify(submit)
      });
      if (rawResponse.status === 201 || rawResponse.status === 200) {
        const content = await rawResponse.json();
        if (content?.messageType === 1) {
          setdata(content?.item)
        } else {
          alert("No Data Found");
          setdata(null);
        }

      } else {
        alert("Something went wrong");

      }

    }
    catch (err) {
      // not jumping in here.
      console.log(err)
    }
  }


  // https://api.shebashikkha.com/public/online/admission/confirmation/letter/download?instituteId=1&registrationId=2400001


  function downloadPdf() {
    window.open(`${process.env.REACT_APP_API_ROOT}/public/online/admission/confirmation/letter/download?instituteId=${parseInt(match.params.id)}&registrationId=${textid}`);
  }

  function downloadAdmitCard() {

    let paymentStatus = data?.paymentStatus;

    if (paymentStatus === 'Unpaid') {
      console.log('------------------', paymentStatus);

      alert("Please Pay your application fee to download Admit Card.")

    } else {
      window.open(`${process.env.REACT_APP_API_ROOT}/public/online/admission/admit/card/download?instituteId=${parseInt(match.params.id)}&registrationId=${textid}`);
    }

  }

  function viewForm() {
    history.push("/online-applicant-info/" + parseInt(match.params.id));
  }
  return (
<Grid container direction="column" spacing={3} style={{ minHeight: "100vh" }}>
  {/* Header Section */}
  <Grid item xs={12}>
    <HeaderadmissionLottery
      details={{
        instituteName: instInfo?.institutename,
        address: instInfo?.instituteaddress,
        image: instInfo?.imageLink,
      }}
    />
  </Grid>

  {/* Main Content Section */}
  <Grid
    item
    xs={12}
    style={{
      background: "#fff",
      boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
      padding: "20px",
      borderRadius: "8px",
    }}
  >
    {data !== null && (
      <Grid container spacing={4} alignItems="center">
        {/* Student Image */}
        <Grid item xs={12} md={4} style={{ textAlign: "center" }}>
          <img
            src={data?.studentImageLink}
            alt="Student"
            style={{
              width: "100%",
              maxWidth: "500px",
              height: "auto",
              borderRadius: "12px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            }}
          />
        </Grid>

        {/* Student Details */}
        <Grid item xs={12} md={8}>
          <Viewfieldlarge label="Applicant Id" name={data?.registrationId} />
          <Viewfieldlarge label="Student Name" name={data?.applicantName} />
          <Viewfieldlarge label="Father's Name" name={data?.fatherName} />
          <Viewfieldlarge label="Mother's Name" name={data?.motherName} />
          <Viewfieldlarge label="Applied Class" name={data?.className} />
          <Viewfieldlarge label="Gender" name={data?.gender} />
        </Grid>
      </Grid>
    )}
  </Grid>

  {/* Form Section */}
  <Grid item xs={12} style={{ marginTop: "20px", marginBottom: "40px" }}>
    <form onSubmit={formSubmit}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={6}>
          <TextField
            id="outlined-basic"
            label="Applicant Id"
            variant="outlined"
            fullWidth
            required
            type="number"
            onChange={(e) => settextid(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Buttonh
            type="submit"
            color="primary"
            variant="contained"
            fullWidth
            style={{ height: "56px" }}
          >
            Search
          </Buttonh>
        </Grid>
      </Grid>
    </form>
  </Grid>
</Grid>

  );
};

export default AdmissionLottery;
