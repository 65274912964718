import React from 'react';
import { Toolbar, AppBar, Typography, Container, Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import useReactRouter from 'use-react-router';
const AppMenu = (props) => {
  // console.log('hello', props)
  const history = useHistory();
  const { match } = useReactRouter();

  function goHome() {
    history.push(`/online-admission/${match.params.id}`);
  }
  function viewView() {
    history.push(`/online-admission-view/${match.params.id}`);
  }   
  // function editView() {
  //   history.push(`/online-admission-edit/${match.params.id}`);
  // }  
  function viewPayment() {
    history.push(`/online-admission-payment/${match.params.id}`);
  }


  function downloadForm() {
    history.push(`/online-admission-download/${match.params.id}`);
  }


  function lottery() {
    history.push(`/online-admission-lotarry/${match.params.id}`);
  }


  return (
    <>
      <div style={{ display: "flex", marginBottom: "20px", marginTop: "20px", justifyContent: "center" }}>
        <Button variant="contained" color="primary" style={{ marginRight: "10px" }} onClick={() => goHome()}> Home</Button>
        <Button variant="contained" color="primary" style={{ marginRight: "10px" }} onClick={() => viewView()}> View</Button>
        {/* <Button variant="contained" color="primary" style={{ marginRight: "10px" }} onClick={() => editView()}> Edit</Button> */}
        {/* <Button variant="contained" color="primary" style={{ marginRight: "10px" }} onClick={() => viewPayment()}> Payment</Button> */}
        <Button variant="contained" color="primary" style={{ marginRight: "10px" }} onClick={() => downloadForm()}> Download</Button>
        
      </div>

    </>
  );
};

export default AppMenu;